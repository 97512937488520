import 'focus-visible'

import { registerApplication, start } from 'single-spa'

registerApplication({
  name: 'tdp-repo-list',
  app: () => global.System.import('tdp-repo-list'),
  activeWhen: () => true
})

registerApplication({
  name: 'spa-dev-tools',
  app: () => global.System.import('spa-dev-tools'),
  activeWhen: () => !!window.importMapOverrides
})

start()
